<template>
    <div class="animated fadeIn">
        <wit-tabbed-form
            :formId="formId"
            :steps="steps"
            headerLogo="logotypes/awin_36.svg"
            headerText="Setup Your Awin Collect"
            @continue="continueForm"
            @reset="resetForm"
            @input="onFormInput"
            @update="onFormUpdate"
            ref="tabbedForm"
            @finish="step => (isDetails ? updateCollect(step) : createCollect(step))"
            :details="isDetails"
            :dirty="dirty"
            :fetched="fetched"
            :update="updateCollect"
            :update-redirect="updateRedirect"
        >
            <template v-slot:step-2-feedback>
                <b-row>
                    <b-col>
                        <Feedback
                            v-if="feedbackError"
                            :state="feedbackError.state"
                            :invalid="feedbackError.message"
                        ></Feedback>
                    </b-col>
                </b-row>
            </template>
        </wit-tabbed-form>

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'

import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import WitTabbedForm from '@/components/WitTabbedForm.vue'

import {collectMixin} from '@/mixins/collectMixin'
import {formMixin} from '@/mixins/formMixin'
import {filterDirty} from '@/shared/filterDirty.js'

const cloneDeep = require('lodash.clonedeep')

export default {
    components: {
        Feedback,
        Loading,
        WitTabbedForm,
    },
    data() {
        return {
            collectForm: {
                name: '',
                tokenId: null,
                settings: {
                    region: null,
                    account: '',
                },
            },
            workflowForm: {
                historicalDateRange: 30,
            },
            currentCollectForm: {},
            feedbackError: null,
            regions: [],
            accounts: [],
        }
    },
    mixins: [collectMixin, formMixin, validationMixin],
    validations: {
        collectForm: {
            name: {required},
            tokenId: {required},
            settings: {
                region: {required},
                account: {required},
            },
        },
        workflowForm: {
            historicalDateRange: {},
        },
    },
    async created() {
        await this.fetchRegions()

        if (!this.isDetails) {
            const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
            const formSessionId = formSessionMap[this.$route.path].id

            this.$gtm.trackEvent({
                event: 'create_resource_start',
                action: 'create',
                value: formSessionId,
            })
        } else {
            this.fetchCollect()
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            loading: 'loading/state',
        }),
        isDetails() {
            return Boolean(this.$route.params.id)
        },
        cannotContinue() {
            return this.isDetails
        },
        formId() {
            return this.isDetails ? 'awin-create-form' : 'awin-details-form'
        },
        steps() {
            return [
                {
                    name: 'Collect Settings',
                    invalid: this.$v.collectForm.$invalid,
                    inputs: [
                        {
                            name: 'name',
                            value: this.$v.collectForm.name.$model,
                            model: this.$v.collectForm.name,
                            type: 'text',
                            inputLabel: 'Collect name',
                            placeholder: 'Enter your collect name',
                            invalid: 'This field is required',
                            valid: 'Name is valid',
                        },
                        {
                            name: 'token',
                            value: this.$v.collectForm.tokenId.$model,
                            model: this.$v.collectForm.tokenId,
                            type: 'awin-token',
                            inputLabel: 'Select Awin token',
                            disabled: this.isDetails,
                            preselectFirst: true,
                            onInput: this.onTokenInput,
                        },
                        {
                            name: 'region',
                            value: this.$v.collectForm.settings.region.$model,
                            model: this.$v.collectForm.settings.region,
                            type: 'select',
                            inputLabel: 'Region',
                            options: this.regions,
                            placeholder: 'Select region',
                            invalid: 'This field is required',
                            valid: 'Region is valid',
                        },
                        {
                            name: 'account',
                            value: this.$v.collectForm.settings.account.$model,
                            model: this.$v.collectForm.settings.account,
                            type: 'select',
                            inputLabel: 'Account',
                            options: this.accounts,
                            label: 'accountName',
                            placeholder: this.accounts.length
                                ? 'Select the account'
                                : 'Authorize with Awin or select a token first',
                            invalid: 'This field is required',
                            valid: 'Account is valid',
                        },
                    ],
                },
                this.isDetails
                    ? null
                    : {
                          name: 'Data Collection Settings',
                          invalid: this.$v.collectForm.$invalid || this.$v.workflowForm.$invalid,
                          inputs: [
                              {
                                  name: 'range',
                                  value: this.$v.workflowForm.historicalDateRange.$model,
                                  model: this.$v.workflowForm.historicalDateRange,
                                  type: 'range',
                                  inputLabel: 'Collect data for last (days)',
                                  min: 0,
                                  max: 365,
                              },
                          ],
                      },
            ].filter(Boolean)
        },
        dirty() {
            const collectFormDirty = filterDirty(this.collectForm, this.currentCollectForm)
            return Object.keys(collectFormDirty).length > 0
        },
    },
    methods: {
        async fetchCollect() {
            this.$store.commit('loading/PROCESSING', `Fetching...`)

            const response = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/collect/awin/${this.$route.params.id}`
            )
            this.collect = response.data.data

            await this.onTokenInput(this.collect.tokenRef.id)
            this.$store.commit('loading/PROCESSING', `Preparing...`)

            this.collectForm = {
                name: this.collect.name,
                tokenId: this.collect.tokenRef.id,
                settings: {
                    region: this.collect.settings.region,
                    account: this.accounts.find(el => el.accountId === this.collect.settings.accountId),
                },
            }

            this.currentCollectForm = cloneDeep(this.collectForm)
            this.fetched = true

            this.$store.commit('loading/PROCESSED')
        },

        async updateCollect() {
            if (!this.$v.$invalid) {
                const collectFormDirty = filterDirty(this.collectForm, this.currentCollectForm)
                if (Object.keys(collectFormDirty).length > 0) {
                    const {name, settings} = collectFormDirty

                    const data = {
                        name: name ? name : undefined,
                        settings: settings
                            ? {
                                  region: settings.region ? this.collectForm.settings.region : undefined,
                                  accountId: settings.account ? this.collectForm.settings.account.accountId : undefined,
                              }
                            : undefined,
                    }

                    this.$store.commit('loading/PROCESSING', `Updating...`)
                    try {
                        await this.axios.post(
                            `${process.env.VUE_APP_NODE_API_HOST}/collect/awin/${this.$route.params.id}`,
                            data
                        )

                        this.feedbackError = {}
                        this.currentCollectForm = cloneDeep(this.collectForm)
                        this.$forceUpdate()
                        this.$v.$reset()

                        this.$store.commit('loading/PROCESSED')
                    } catch (exception) {
                        this.$store.commit('loading/PROCESSED')
                        this.feedbackError = {
                            state: false,
                            message: exception.response.data.data,
                        }
                        this.$errorHandler.report(exception, 'Could not update form')
                    }
                }
            }
        },

        async createCollect(step) {
            step.check()
            this.$forceUpdate()

            if (!this.$v.$invalid) {
                this.feedbackError = {
                    state: null,
                    message: '',
                }

                const data = {
                    name: this.collectForm.name,
                    tokenId: this.collectForm.tokenId,
                    settings: {
                        region: this.collectForm.settings.region,
                        accountId: this.collectForm.settings.account.accountId,
                    },
                    ...this.workflowForm,
                }

                this.$store.commit('loading/PROCESSING', `Creating your Collect...`)
                this.axios
                    .post(`${process.env.VUE_APP_NODE_API_HOST}/collect/awin`, data)
                    .then(response => {
                        this.$store.commit('loading/PROCESSED')
                        this.feedbackError = {}
                        this.resetForm()

                        const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
                        const formSessionId = formSessionMap[this.$route.path].id

                        this.$gtm.trackEvent({
                            event: 'create_resource_finish',
                            action: 'finish',
                            value: formSessionId,
                        })

                        formSessionMap[this.$route.path] = null
                        localStorage.setItem('formSessionIdMap', JSON.stringify(formSessionMap))

                        this.$projectRouter.push(this.redirectUrl)
                    })
                    .catch(exception => {
                        this.$errorHandler.report(exception, 'Could not create form')
                        this.$store.commit('loading/PROCESSED')
                        this.feedbackError = {
                            state: false,
                            message: exception.response.data.data,
                        }
                    })
            }
        },

        async fetchRegions() {
            this.$store.commit('loading/PROCESSING', `Fetching regions...`)

            const response = await this.axios.get(`${process.env.VUE_APP_NODE_API_HOST}/collect/awin/awinRegions`)
            this.regions = Object.keys(response.data.data)

            this.$store.commit('loading/PROCESSED')
        },

        async onTokenInput(tokenId) {
            if (tokenId || this.collectForm.tokenId) {
                this.$store.commit('loading/PROCESSING', `Fetching accounts...`)
                try {
                    if (!this.isDetails) {
                        this.collectForm.accounts = []
                    }

                    const response = await this.axios.get(
                        `${process.env.VUE_APP_NODE_API_HOST}/collect/awin/awinAccounts?tokenId=${
                            tokenId ? tokenId : this.collectForm.tokenId
                        }`
                    )
                    const {accounts} = response.data.data
                    this.accounts = accounts

                    this.$store.commit('loading/PROCESSED')
                } catch (exception) {
                    this.$store.commit('loading/PROCESSED')
                    this.feedbackError.state = false
                    this.feedbackError.message = exception.response.data.data
                }
            }
        },
    },
}
</script>

<style lang="scss"></style>
